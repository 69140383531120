import type { FC, ReactNode } from "react"

export interface MessageProps {
  heading?: string | null
  title?: string | null
  description?: string | null
  action?: ReactNode
}

export const Message: FC<MessageProps> = (props) => {
  const {
    action,
    description = (
      <>
        It&apos;s not you. it&apos;s us.
        <div>Thank you!</div>
      </>
    ),
    heading = <>Apologies, something seems to have gone wrong on our end.</>,
    title,
  } = props

  return (
    <div className="my-8 flex h-full flex-1 flex-col items-center justify-center space-y-4">
      {heading && <h1 className="text-red-150 text-2xl">{heading}</h1>}
      {title && <h5 className="text-md">{title}</h5>}
      {description && <h3 className="text-muted text-lg">{description}</h3>}
      {action}
    </div>
  )
}
