import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GlobalStore, Sections } from "./Global.interface"

const initialState: GlobalStore = {
  view: Sections.Title,
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setView: (state, { payload }: PayloadAction<Sections>): GlobalStore => {
      return { ...state, view: payload }
    },
  },
})

export const { setView } = globalSlice.actions
export default globalSlice.reducer
