export enum Sections {
  "Title" = "title",
  "About" = "about",
  "Skill" = "skill",
  "Service" = "service",
  "Work" = "work",
  "Education" = "education",
  "Contact" = "contact",
}

export interface GlobalStore {
  view: Sections
}
