import React, { forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect, useMemo, useState } from "react"
import ReactFlow, {
  Background,
  BackgroundVariant,
  ConnectionLineComponent,
  CoordinateExtent,
  Edge,
  EdgeTypes,
  Handle,
  NodeProps,
  Panel,
  Position,
  useEdgesState,
  useNodesState,
} from "reactflow"
import "reactflow/dist/base.css"
import "reactflow/dist/style.css"
import { useHover } from "../../hooks/useHover"
import { Sections } from "../../redux/slice"
import { useAppSelector } from "../../redux/store/hooks"
import { initEdges, initNodes } from "./data"
import { FloatingConnectionLine } from "./FloatingConnectionLine"
import { FloatingEdge } from "./FloatingEdge"
import { NodeData, SkillsProps } from "./Skills.interface"

const nodeTypes = {
  main: MainNode,
  category: CategoryNode,
  technology: TechnologyNode,
}

const edgeTypes: EdgeTypes = {
  floating: FloatingEdge as any,
}

export const Skills: ForwardRefExoticComponent<SkillsProps & RefAttributes<HTMLElement>> = forwardRef((props, ref) => {
  const { className } = props
  const view = useAppSelector((store) => store.global.view)

  const [nodes] = useNodesState<NodeData>(initNodes)
  const [edges] = useEdgesState<Edge>(initEdges)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (view === Sections.Skill) {
      setShow(true)
      setTimeout(() => setShow(false), 5000)
    }
  }, [view])

  const translateExtent = useMemo<CoordinateExtent>(
    () =>
      nodes.reduce(
        ([[left, top], [right, bottom]], { position, width, height }) => [
          [
            Math.min(left, (position ?? { x: Infinity }).x - (width ?? 0) / 2 - 250),
            Math.min(top, (position ?? { y: Infinity }).y - (height ?? 0) / 2 - 250),
          ],
          [
            Math.max(right, (position ?? { x: -Infinity }).x + (width ?? 0) / 2 + 250),
            Math.max(bottom, (position ?? { y: -Infinity }).y + (height ?? 0) / 2 + 250),
          ],
        ],
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity],
        ],
      ),
    [nodes],
  )

  return (
    <section ref={ref} className={`${className} h-[80vh] w-full`}>
      <ReactFlow
        translateExtent={translateExtent}
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        defaultViewport={{ x: window.innerWidth / 2 - 261 / 2, y: window.innerHeight / 2 - 210 / 2, zoom: 0.6 }}
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        onNodeMouseEnter={() => {}}
        nodesDraggable={false}
        connectionLineComponent={FloatingConnectionLine as unknown as ConnectionLineComponent}
        className="bg-white bg-opacity-10"
      >
        <Background variant={BackgroundVariant.Cross} gap={100} size={10} />
        <div className={`pointer-events-none transition-opacity duration-500 ${show ? "opacity-100" : "opacity-0"}`}>
          <Panel className="react-flow__node-input w-auto" position="bottom-right">
            Try scolling and panning
          </Panel>
          <Panel className="react-flow__node-input mb-16 w-auto" position="bottom-right">
            Try click or tap on icons
          </Panel>
        </div>
      </ReactFlow>
    </section>
  )
})

function MainNode({ data }: NodeProps<NodeData>) {
  return (
    <>
      <div className="react-flow__node-input w-auto border-0 bg-transparent text-7xl font-semibold text-white">{data.label}</div>
      <Handle type="target" position={Position.Top} className="w-0 border-none bg-transparent" />
      <Handle type="source" position={Position.Bottom} className="w-0 border-none bg-transparent" />
    </>
  )
}

function CategoryNode({ data }: NodeProps<NodeData>) {
  return (
    <>
      <div className="react-flow__node-input relative min-h-[200px] w-[300px] p-6 text-left">
        <div className="absolute right-0 top-0 mr-6 -translate-y-1/2 translate-x-1/2 text-red-800">{data.icon}</div>
        <div className="mb-3 text-2xl font-semibold leading-none">{data.label}</div>
        <p>{data.description}</p>
      </div>
      <Handle type="target" position={Position.Top} className="w-0 border-none bg-transparent" />
      <Handle type="source" position={Position.Bottom} className="w-0 border-none bg-transparent" />
    </>
  )
}

function TechnologyNode({ data, selected }: NodeProps<NodeData>) {
  const [ref, isHover] = useHover()

  return (
    <>
      <div ref={ref} className="relative h-[90px] w-[90px] border-4 border-dotted border-primary text-6xl">
        <div className="flex h-full w-full items-center justify-center overflow-hidden pb-4">
          {typeof data.icon === "string" ? (
            <img src={data.icon} alt={data.label} />
          ) : (
            {
              ...data.icon,
              props: {
                ...(data.icon.props ?? {}),
                className: `${data.icon.props?.className} ${isHover || selected ? (data.customIcon ? "text-primary" : "colored") : ""}`,
              },
            }
          )}
        </div>
        <div className="absolute bottom-0 mb-1 w-full text-center text-xs">{data.label}</div>

        {selected && (
          <div className="absolute -top-full left-3/4 z-10 w-96 bg-white p-4 text-xs text-black shadow-md shadow-slate-600">
            <h3 className="mb-1 text-sm font-semibold">{data.label}</h3>
            <p>{data.description}</p>
          </div>
        )}
      </div>
      <Handle type="target" position={Position.Top} className="w-0 border-none bg-transparent" />
      <Handle type="source" position={Position.Bottom} className="w-0 border-none bg-transparent" />
    </>
  )
}
