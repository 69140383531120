import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react"

interface Props {
  className?: string
}

export const About: ForwardRefExoticComponent<Props & RefAttributes<HTMLElement>> = forwardRef((props, ref) => {
  const { className } = props
  return (
    <section ref={ref} id="About" className={`${className} space-y-16 text-2xl font-semibold leading-tight md:text-7xl`}>
      <div className="text-[1.75em]">— Hello World</div>
      <p>
        I'm Ashfahan (ASH-FAA-HAAN), a creative developer originally from Dera Ismail Khan, now based in Islamabad. I help people/brands by creating
        visually appealing and user-friendly interfaces for websites and applications, making it easier for users to interact with digital products.
        This enhances their user experience and improves overall satisfaction, resulting in increased engagement and customer loyalty.
      </p>
      {/* <p>
        Currently, I am working at BlueMeg (HongKong) and collaborating with several companies, including Ona, Venture Dive, Epik, as well as
        freelance agencies such as Assembly Soft and a few others.
      </p> */}
      <p>
        As a passionate, self-taught, and quality-oriented ReactJS Front End Programmer, I am qualified and productive in problem-solving, a fast
        learner, and a team player.
      </p>
      <p>During my spare time, I enjoy watching anime and exploring new technologies and products on Product Hunt.</p>
      <p>
        I am a hardworking and ambitious individual, capable of adapting to new situations and comfortable working in a team. I have had the privilege
        of working with various software agencies, where I have honed valuable professional skills such as programming, website architecture, database
        design, and exposure to new tools and languages.
      </p>
    </section>
  )
})
