import { CSSProperties, FC, HTMLAttributes, useRef } from "react"

export interface ParallexImageProps {
  image: string
  speed?: number
  size?: CSSProperties["objectFit"]
  lag?: number
  blur?: number
  darken?: number
  children?: JSX.Element
  className?: string
}

export const ParallexImage: FC<ParallexImageProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { image, blur, children, className, darken, size = "cover", lag, speed = 0.4, ...rest } = props
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div ref={ref} className={`relative z-0 overflow-hidden ${className}`} {...rest}>
      <aside>
        <img
          style={{ ...(size && { objectFit: size }) }}
          data-speed={speed}
          data-lag={lag}
          className="absolute -top-80 left-0 z--10 min-h-screen w-full"
          src={image}
          alt="img"
        />
      </aside>
      <div className="relative">{children}</div>
    </div>
  )
}
