import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthStore } from "./Auth.interface"

const initialState: AuthStore = {
  accessToken: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      localStorage.clear()
      sessionStorage.clear()
      return initialState
    },
    setToken: (state, { payload }: PayloadAction<string>): AuthStore => {
      return { ...state, accessToken: payload }
    },
  },
})

export const { reset, setToken } = authSlice.actions
export default authSlice.reducer
