import { CssBaseline, ThemeProvider } from "@mui/material"
import React, { useMemo } from "react"
import { getTheme } from "../helpers/getTheme"
import { useAppSelector } from "../redux/store/hooks"
import { Footer } from "./Footer"
import { LayoutProps } from "./Layout.interfaces"

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const isAuthenticated = true

  const theme = useAppSelector((store) => store.userSettings.theme)

  const fullPage = false

  const ThemeSelected = useMemo(() => getTheme(theme), [theme])

  return (
    <ThemeProvider theme={ThemeSelected.theme}>
      <CssBaseline />
      <div className={`flex ${ThemeSelected.name === "dark" && "dark"}`}>
        <div className="flex min-h-screen flex-1 flex-col justify-between">
          {children(isAuthenticated)}
          {!fullPage && <Footer />}
        </div>
      </div>
      {/* <FollowCircle /> */}

      {/* <BackTop style={{ right: 30 }}>
        <i className="ri-3x bg-white clr-black o-50 rounded-full ri-arrow-up-circle-fill" />
      </BackTop> */}
    </ThemeProvider>
  )
}
