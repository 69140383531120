import { RouteObject } from "react-router-dom"
import { NotFound } from "../containers/NotFound"
import { Home } from "../pages/Home"

export interface RoutePram extends Record<string, string | undefined> {}

export interface PathRoute extends Omit<RouteObject, "children" | "element"> {
  element?: JSX.Element
  children?: PathRoute[]
  render?: boolean
}

export const URL_HOME = "/"

export const OPEN_ROUTES: PathRoute[] = [
  {
    path: URL_HOME,
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]

export const AUTHENTICATED_ROUTES: PathRoute[] = []

export const UNAUTHENTICATED_ROUTES: PathRoute[] = []
