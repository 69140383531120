import { Button, IconButton } from "@mui/material"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { debounce } from "lodash"
import React, { FC, useEffect, useRef, useState } from "react"
import { Sections } from "../../redux/slice"
import { useAppSelector } from "../../redux/store/hooks"
import { SidebarProps } from "./Sidebar.interface"

export const Sidebar: FC<SidebarProps> = (props) => {
  return (
    <div className="fixed bottom-0 left-0 top-0 z-10 flex min-h-screen w-24 flex-col l-10 pt-4 text-base">
      <div className="mt-2 flex w-full flex-col items-center space-y-4">
        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/ashfahan/"
          className="group space-x-4 border-2 border-transparent transition-colors hover:border-current"
          color="inherit"
        >
          <i className="icon ri-linkedin-fill" />
          <span className="pointer-events-none absolute left-full top-1/2 -translate-y-1/2 whitespace-nowrap text-base opacity-0 transition-opacity group-hover:opacity-100">
            Linkedin
          </span>
        </IconButton>

        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          href="http://github.com/ashfahan"
          className="group space-x-4 border-2 border-transparent transition-colors hover:border-current"
          color="inherit"
        >
          <i className="icon ri-github-fill" />
          <span className="pointer-events-none absolute left-full top-1/2 -translate-y-1/2 whitespace-nowrap text-base opacity-0 transition-opacity group-hover:opacity-100">
            Github
          </span>
        </IconButton>

        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.behance.net/ashfahan"
          className="group space-x-4 border-2 border-transparent transition-colors hover:border-current"
          color="inherit"
        >
          <i className="icon ri-behance-fill" />
          <span className="pointer-events-none absolute left-full top-1/2 -translate-y-1/2 whitespace-nowrap text-base opacity-0 transition-opacity group-hover:opacity-100">
            Behance
          </span>
        </IconButton>

        <IconButton
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:me@ashfahan.com"
          className="group space-x-4 border-2 border-transparent transition-colors hover:border-current"
          color="inherit"
        >
          <i className="icon ri-mail-line" />
          <span className="pointer-events-none absolute left-full top-1/2 -translate-y-1/2 whitespace-nowrap text-base opacity-0 transition-opacity group-hover:opacity-100">
            work@ashfahan.com
          </span>
        </IconButton>

        <IconButton
          download
          href="/Ashfahan Resume.pdf"
          className="group space-x-4 border-2 border-transparent transition-colors hover:border-current"
          color="inherit"
        >
          <i className="icon ri-file-user-fill" />
          <span className="pointer-events-none absolute left-full top-1/2 -translate-y-1/2 whitespace-nowrap text-base opacity-0 transition-opacity group-hover:opacity-100">
            Resume
          </span>
        </IconButton>
      </div>

      <SectionArea />

      <ScrollDown />
    </div>
  )
}

const SectionArea: FC = () => {
  const storeview = useAppSelector((store) => store.global.view)

  const [view, setView] = useState<{ now: typeof storeview; prev?: typeof storeview }>({
    now: Sections.Title,
    prev: undefined,
  })

  const ChangeArea = debounce(() => setView((prev) => ({ now: storeview, prev: prev.now })), 250)

  useEffect(() => {
    ChangeArea()
    return ChangeArea.cancel
  }, [storeview, ChangeArea])

  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center text-xl font-semibold [writing-mode:vertical-lr]">
      <div className={`animate__animated absolute ${view.now === Sections.Title ? "animate__fadeInLeft" : "animate__fadeOutLeft"}`}></div>
      <div className={`animate__animated absolute ${view.now === Sections.About ? "animate__fadeInLeft" : "animate__fadeOutLeft"}`}>About</div>
      <div className={`animate__animated absolute ${view.now === Sections.Skill ? "animate__fadeInLeft" : "animate__fadeOutLeft"}`}>Skills</div>
      <div className={`animate__animated absolute ${view.now === Sections.Work ? "animate__fadeInLeft" : "animate__fadeOutLeft"}`}>Work</div>
    </div>
  )
}

export const ScrollDown: FC = () => {
  const ref = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const scrolldown = ref.current
    gsap.registerPlugin(ScrollTrigger)
    if (scrolldown)
      gsap.to(scrolldown, {
        scrollTrigger: {
          onEnter: () => scrolldown.classList.add("opacity-0", "pointer-events-none"),
          onLeaveBack: () => scrolldown.classList.remove("opacity-0", "pointer-events-none"),
          start: 300,
          end: "+=0",
        },
      })
  }, [ref])

  return (
    <Button
      ref={ref}
      onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
      className="absolute bottom-0 left-0 flex flex-col transition-opacity"
      color="inherit"
    >
      Scroll Down
      <span className="relative flex h-20 overflow-hidden">
        <span className="block border-2 border-r border-neutral-800"></span>
        <span className="scrolldown absolute block h-1/2 border-2 border-r"></span>
      </span>
    </Button>
  )
}
