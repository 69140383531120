import ScrollTrigger from "gsap/ScrollTrigger"
import { useEffect, useRef } from "react"
import { Skills } from "../../components/Skills"
import { Sidebar } from "../../layouts/Sidebar"
import { AppDispatch } from "../../redux"
import { Sections, setView } from "../../redux/slice"
import { useAppDispatch } from "../../redux/store/hooks"
import { About } from "../../section/About"
import { Title } from "../../section/Title"
import { Work } from "../../section/Work"
import { ScrollSmoother } from "gsap/ScrollSmoother"

const CreateScrollTrigger = (dispatch: AppDispatch, options: Partial<ScrollTrigger.StaticVars>, section: Sections) => {
  return ScrollTrigger.create({
    start: "top center",
    onEnter: () => dispatch(setView(section)),
    onEnterBack: () => dispatch(setView(section)),
    end: "bottom center",
    ...options,
  })
}

export const Home = () => {
  const dispatch = useAppDispatch()
  const titleRef = useRef<HTMLDivElement | null>(null)
  const aboutRef = useRef<HTMLDivElement | null>(null)
  const skillsRef = useRef<HTMLDivElement | null>(null)
  const workRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (titleRef.current) CreateScrollTrigger(dispatch, { trigger: titleRef.current }, Sections.Title)
    if (aboutRef.current) CreateScrollTrigger(dispatch, { trigger: aboutRef.current }, Sections.About)
    if (skillsRef.current) CreateScrollTrigger(dispatch, { trigger: skillsRef.current }, Sections.Skill)
    if (workRef.current) CreateScrollTrigger(dispatch, { trigger: workRef.current }, Sections.Work)
  }, [dispatch])

  useEffect(() => {
    ScrollSmoother.create({
      wrapper: "#root",
      content: document.querySelector("main"),
      smooth: 2,
      effects: true,
    })
  }, [])

  return (
    <>
      <div className="img-bg fixed inset-0 bg-black bg-background bg-fixed" />
      <div className="relative bg-black bg-opacity-90 text-white">
        <Sidebar />
        <main>
          <Title ref={titleRef} />
          <About className="mx-auto max-w-screen-2xl p-28 pr-14" ref={aboutRef} />
          <Skills className="mx-auto max-w-screen-2xl" ref={skillsRef} />
          <Work ref={workRef} />
        </main>
      </div>
    </>
  )
}
