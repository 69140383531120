import { createTheme, ThemeOptions as MuiThemeOptions } from "@mui/material"
import merge from "deepmerge"
import { CSSProperties } from "react"
import {
  amber,
  black,
  blue,
  current,
  cyan,
  emerald,
  fuchsia,
  gray,
  green,
  indigo,
  lime,
  neutral,
  orange,
  pink,
  purple,
  red,
  rose,
  sky,
  slate,
  stone,
  teal,
  violet,
  white,
  yellow,
  zinc,
} from "tailwindcss/colors"

export interface ThemeOptions extends MuiThemeOptions {
  components?: MuiThemeOptions["components"] & {
    MuiSelect?: {
      styleOverrides?: {
        root?: CSSProperties
      }
    }
  }
}

export const themeColor = merge(
  {
    slate,
    gray,
    zinc,
    neutral,
    stone,
    red,
    orange,
    amber,
    yellow,
    lime,
    green,
    emerald,
    teal,
    cyan,
    sky,
    blue,
    indigo,
    violet,
    purple,
    fuchsia,
    pink,
    rose,
    current,
    black,
    white,
  },
  {
    primary: {
      light: "#B29BE6",
      main: "#805AD5",
      dark: "#522BA8",
    },

    secondary: {
      light: "#3A414B",
      main: "#282D34",
      dark: "#16191D",
    },
  },
)

const LIGHT: ThemeOptions = {
  palette: {
    ...themeColor,
    grey: themeColor.neutral,
    common: {
      black: themeColor.black,
      white: themeColor.white,
    },
    background: {
      default: themeColor.neutral[100],
      paper: themeColor.white,
    },
  },
  typography: {
    fontFamily: [
      "Gilroy",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        inputSizeSmall: { paddingTop: ".25rem !important", paddingBottom: ".25rem !important" },
        input: { paddingTop: ".7rem !important", paddingBottom: ".7rem !important" },
        adornedStart: { paddingLeft: ".5rem !important", "& svg": { marginRight: ".25rem" } },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          display: "inline-block",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: { paddingTop: "0 !important", paddingBottom: "0 !important" },
      },
    },

    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: { minWidth: "unset", textTransform: "inherit", boxShadow: "none", ":hover": { boxShadow: "none" } },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: { color: "inherit" },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: { background: "inherit" },
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        root: { background: "inherit", color: "inherit" },
      },
    },

    MuiList: {
      styleOverrides: {
        root: { background: "inherit" },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 12px 23px 0px rgb(62 73 84 / 4%)",
          padding: "2rem",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: { fontSize: "inherit" },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          "&::before,&::after": { content: "none" },
          background: "transparent",
          "&:hover::not(disabled)": {
            background: "transparent",
          },
        } as any,
        filled: {
          margin: "0px -1px",
          border: "1px solid",
          borderRadius: "4px",

          // "&.MuiInputBase-sizeSmall": { height: "27.125px" },
          // "&:not(.MuiInputBase-sizeSmall)": { height: "2.8375em" },
          "&.Mui-focused:after": { opacity: 1 },
          "& fieldset": { border: 0 },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            backgroundColor: themeColor.gray[100],
            borderRadius: "4px",

            "&.Mui-focused:after": { opacity: 1 },
            "& fieldset": { border: 0 },
            "&::before,&::after": { content: "none" },
          },
        },
      },
    },
  },
}

const DARK: ThemeOptions = merge(
  { ...LIGHT },
  {
    palette: {
      mode: "dark",
      grey: {
        "50": themeColor.neutral[50],
        "100": themeColor.neutral[900],
        "200": themeColor.neutral[800],
        "300": themeColor.neutral[700],
        "400": themeColor.neutral[600],
        "500": themeColor.neutral[500],
        "600": themeColor.neutral[400],
        "700": themeColor.neutral[300],
        "800": themeColor.neutral[200],
        "900": themeColor.neutral[100],
      },
      amber: {
        "50": themeColor.amber[50],
        "100": themeColor.amber[900],
        "200": themeColor.amber[800],
        "300": themeColor.amber[700],
        "400": themeColor.amber[600],
        "500": themeColor.amber[500],
        "600": themeColor.amber[400],
        "700": themeColor.amber[300],
        "800": themeColor.amber[200],
        "900": themeColor.amber[100],
      },
      secondary: {
        main: themeColor.neutral[300],
        light: themeColor.neutral[600],
        dark: themeColor.neutral[100],
      },
      common: {
        black: themeColor.white,
        white: themeColor.black,
      },
      background: {
        default: themeColor.neutral[900],
        paper: themeColor.black,
      },
    },
  },
)

export const LIGHTTHEME = createTheme(LIGHT)
export const DARKTHEME = createTheme(DARK)
