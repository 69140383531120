import { Button } from "@mui/material"
import { gsap, Power3 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect, useRef } from "react"

interface Props {
  className?: string
}

export const Title: ForwardRefExoticComponent<Props & RefAttributes<HTMLElement>> = forwardRef((props, ref) => {
  const elements = useRef<Record<string, Element | null>>({})

  useEffect(() => {
    const divs = elements.current as Record<string, Element>
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({ paused: true })
    tl.from(divs.hello, { duration: 1.5, y: "225%", skewY: "30deg", ease: Power3.easeInOut }, "<")
    tl.to(divs.hello, { duration: 1.5, y: "-225%", skewY: "30deg", ease: Power3.easeInOut }, "+=.5")
    tl.from(divs.name, { duration: 1.5, y: "320%", skewY: "30deg", ease: Power3.easeInOut }, "<")
    tl.from(divs.title.children[0], { duration: 2, x: "110%", ease: Power3.easeInOut }, "<")
    tl.from(divs.title.children[1], { duration: 2, x: "-250%", ease: Power3.easeInOut }, "<")
    tl.from(divs.titleoutline.children[0], { duration: 2, x: "110%", ease: Power3.easeInOut }, "<")
    tl.from(divs.titleoutline.children[1], { duration: 2, x: "-250%", ease: Power3.easeInOut }, "<")
    tl.from(divs.picture, { opacity: 0, duration: 2, y: "100%", rotate: "-45deg", ease: Power3.easeInOut }, "<")
    tl.call(() => {
      const scrollTrigger = { start: -5, end: "60%", scrub: 1 }
      gsap.to(divs.nav, { duration: 1, x: "0", ease: Power3.easeOut })
      gsap.to(divs.title.children[0], { x: "-7%", scrollTrigger: { ...scrollTrigger, trigger: divs.title.children[0] } })
      gsap.to(divs.titleoutline.children[0], { x: "-7%", scrollTrigger: { ...scrollTrigger, trigger: divs.titleoutline.children[0] } })
      gsap.to(divs.title.children[1], { x: "7%", scrollTrigger: { ...scrollTrigger, trigger: divs.title.children[1] } })
      gsap.to(divs.titleoutline.children[1], { x: "7%", scrollTrigger: { ...scrollTrigger, trigger: divs.titleoutline.children[1] } })
    })
    tl.play()
  }, [elements])

  return (
    <section
      ref={(el) => {
        elements.current.container = el
        ;(ref as any).current = el
      }}
      className=" relative mb-28 min-h-screen"
    >
      <div className="relative mx-auto max-w-screen-2xl">
        <nav
          ref={(el) => (elements.current.nav = el)}
          className="pointer-events-auto absolute right-0 top-0 z-20 mr-5 mt-10 space-y-2 text-right md:mr-20 md:text-2xl"
        >
          <div>
            <Button href="#about" size="large" className="is-min p-0 pb-1 text-[1em] font-semibold before:border-b-4" color="inherit">
              About
            </Button>
          </div>
          <div>
            <Button href="#work" size="large" className="is-min p-0 pb-1 text-[1em] font-semibold before:border-b-4" color="inherit">
              Work
            </Button>
          </div>
          <div>
            <Button href="#skills" size="large" className="is-min p-0 pb-1 text-[1em] font-semibold before:border-b-4" color="inherit">
              Skills
            </Button>
          </div>
          <div>
            <Button href="#contact" size="large" className="is-min p-0 pb-1 text-[1em] font-semibold before:border-b-4" color="inherit">
              Contact
            </Button>
          </div>
        </nav>
      </div>

      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden p-16 text-9xl font-semibold">
        <div ref={(el) => (elements.current.hello = el)}>
          HE<span className="text-stroke-primary text-transparent">LL</span>O<span className="text-primary">.</span>
        </div>
      </div>

      <div className="overflow-hidden p-16 text-center text-6xl font-semibold md:text-7xl">
        <div ref={(el) => (elements.current.name = el)}>
          Ashfahan<span className="text-stroke-primary absolute text-transparent">.</span>
        </div>
      </div>

      <div className="absolute left-0 right-0 top-1/2 mt-16 -translate-y-1/2 overflow-hidden whitespace-nowrap text-center text-9xl font-bold leading-none md:text-[13rem]">
        <div ref={(el) => (elements.current.title = el)}>
          <div style={{ transform: "translate(27%)" }}>Creative Developer</div>
          <div style={{ transform: "translate(-27%)" }}>Front End Developer</div>
        </div>
      </div>

      <div className="absolute left-1/2 top-1/4 mt-16 w-9/12 max-w-xl -translate-x-1/2 rotate-[10deg] overflow-hidden md:mt-0 md:w-auto">
        <img ref={(el) => (elements.current.picture = el)} src="image/me.jpg" alt="" className="img" />
      </div>

      <div className="absolute left-0 right-0 top-1/2 mt-16 -translate-y-1/2 overflow-hidden whitespace-nowrap text-center text-9xl font-bold leading-none md:text-[13rem]">
        <div ref={(el) => (elements.current.titleoutline = el)} className="text-stroke-white text-transparent">
          <div style={{ transform: "translate(27%)" }}>Creative Developer</div>
          <div style={{ transform: "translate(-27%)" }}>Front End Developer</div>
        </div>
      </div>
    </section>
  )
})
