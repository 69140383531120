import { Link } from "@mui/material"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { FC, forwardRef, ForwardRefExoticComponent, HTMLAttributes, RefAttributes, useEffect, useRef } from "react"
import { ParallexImage, ParallexImageProps } from "../components/ParallexImage"

interface Props {
  className?: string
}

export const Work: ForwardRefExoticComponent<Props & RefAttributes<HTMLElement>> = forwardRef((props, ref) => {
  const { className } = props

  const wrapper = useRef<HTMLDivElement>(null)
  const content = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ScrollSmoother.create({
      wrapper: wrapper.current,
      content: content.current,
      smooth: 0,
      effects: true,
    })
  }, [wrapper, content])

  return (
    <section ref={ref} className={className}>
      <div ref={wrapper}>
        <div ref={content}>
          <Project
            link="https://www.behance.net/gallery/188597331/Tapcheck?"
            image="/image/work/tapcheck.jpg"
            name="Tapcheck"
            className="text-secondary"
          />
          <Project
            link="https://www.behance.net/gallery/188414739/Cloudsyte"
            image="/image/work/cloudsyte.png"
            name="Cloudsyte"
            className="text-secondary"
          />
          <Project link="https://www.behance.net/gallery/188411927/BlueMeg" image="/image/work/bluemeg.jpg" name="Bluemeg" />
          <Project link="https://www.behance.net/gallery/169709061/BaseVerify" image="/image/work/baseverify.jpg" name="Baseverify" />
          <Project link="https://www.behance.net/gallery/188482715/adbuq" image="/image/work/adbuq.png" name="Adbuq" className="text-secondary" />
          <Project link="https://www.behance.net/gallery/111086515/Hak-Kak-Blog-Website" image="/image/work/hakandkak.png" name="Hak and Kak" />
          <Project
            link="https://www.behance.net/gallery/110902023/Lone-Bird-Web-Design-Development"
            image="/image/work/lonebirddesign.png"
            name="Lonebird Design"
            className="text-secondary"
          />
          <Project link="https://www.behance.net/gallery/188482873/Ona" image="/image/work/ona.png" name="Ona" className="text-secondary" />
          <Project link="https://www.legacy.com" name="Legacy" image="/image/work/legacy.png" className="text-black" />
          <Project link="https://angelfish.app/" name="Anglefish" image="/image/work/anglefish.png" className="text-black" />
          <Project link="https://bolanrestaurants.com.au/" name="Bolan" image="/image/work/boalnResturent.png" />
          <Project link="https://www.anglefish.com" name="Case Journey" />
          <Project link="https://www.anglefish.com" name="Imami" />
          <Project link="https://www.anglefish.com" name="Matrimonial" />
          <Project link="https://www.anglefish.com" name="Ntask" />
          <Project link="https://www.anglefish.com" name="Punkbodies" />
          <Project link="https://www.anglefish.com" name="QubeLabs" />
          <Project link="https://www.anglefish.com" name="quellxcode" />
          <Project link="https://www.anglefish.com" name="Vidbase" />
          <Project link="https://www.anglefish.com" name="Zoovu" />
          <Project
            link="https://assetstore.unity.com/packages/vfx/shaders/directx-11/pro-car-paint-shader-102063"
            image="/image/work/pcps.png"
            name="Pro Car Paint Shader"
          />
          <Project link="https://www.BoundaryStudio.com" image="/image/work/boundarystudio.png" name="Boundary Studio" status="wip" />
          <Project link="https://www.CoffeeandCoders.com" image="/image/work/cnc.png" name="Coffee & Coders" status="wip" />
        </div>
      </div>
    </section>
  )
})

interface ProjectProps extends HTMLAttributes<HTMLDivElement> {
  status?: "wip" | "completed"
  name: string
  link?: string
  image?: string
  children?: JSX.Element | string
}

const Project: FC<ProjectProps & Partial<ParallexImageProps>> = (props) => {
  const { image, name, link, status = "completed", children, ...rest } = props

  return (
    <ParallexImage
      // darken={0.2}
      speed={0.4}
      {...rest}
      className={`${status === "completed" ? "BebasNeue font-bold" : "Quicksand-Dash"} flex h-[50vh] items-center justify-center ${rest.className}`}
      image={image ?? ""}
    >
      <>
        <Link color="inherit" className="is-min block text-center text-8xl decoration-transparent before:border-b-8" href={link}>
          {name}
        </Link>
        {children}
      </>
    </ParallexImage>
  )
}
