import { Loader } from "containers/Loader"
import { Message } from "containers/Message"
import { FC, ReactNode } from "react"
import { useScript } from "usehooks-ts"

const baseUrl = "//code.tidio.co"
const scriptUrl = baseUrl + "/nablys79auo53xz1mioa9rqbzxl0behr.js"

export const ChatWidgetProvider: FC<{ children: ReactNode; disabled?: boolean }> = ({ children, disabled = false }) => {
  const status = useScript(scriptUrl, {
    removeOnUnmount: false,
    shouldPreventLoad: disabled,
  })

  if (!disabled && status !== "ready") {
    return <div className="flex min-h-screen">{status === "error" ? <Message /> : <Loader />}</div>
  }

  return <>{children}</>
}
